import { useContext, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useAppMessage } from "@daily-co/daily-react";
import { IonIcon } from "@ionic/react";
import * as Icons from "ionicons/icons";
import { observer } from "mobx-react-lite";
import { ParticipantDevice } from "@parallel/vertex/types/meeting.types";
import Button from "@/components/common/elements/Button";
import Select from "@/components/common/elements/Select";
import { IonIconTooltipButton } from "@/components/common/elements/TooltipButton";
import Modal from "@/components/common/modal/Modal";
import DailyParticipantMedia from "@/components/meeting/daily/DailyParticipantMedia";
import { DailyMessageType } from "@/interfaces/message";
import { StoreContext } from "@/stores";
import { classNames } from "@/utils";
import { documentCameraTrackId } from "@/utils/daily.utils";
import { Participant } from "./ParticipantWindowControls";

const _DocumentCameraSelectModal = ({
  participantKey,
  cameras,
  dismiss,
}: {
  participantKey: string;
  cameras: ParticipantDevice[];
  dismiss: () => void;
}) => {
  const { participantStore, meetingStore } = useContext(StoreContext);
  const { remoteDocumentCamera } = meetingStore;

  const sendAppMessage = useAppMessage();

  const [selectedCamera, setSelectedCamera] = useState<ParticipantDevice>(cameras[0]);
  useEffect(() => {
    sendAppMessage({ type: DailyMessageType.StartDocumentCamera, participantKey, deviceId: selectedCamera.deviceId });
  }, [selectedCamera]);

  const clientName = participantStore.participants[participantKey]?.displayName;

  const activate = async () => {
    await meetingStore.updateDisplayState({ activeDocumentCameraId: documentCameraTrackId(selectedCamera.deviceId) });
    dismiss();
  };

  return (
    <Modal
      title="Enable Client Document Camera"
      onClose={() => {
        sendAppMessage({ type: DailyMessageType.StopDocumentCamera, deviceId: selectedCamera.deviceId });
        dismiss();
      }}
      containerStyle={{ width: 600 }}
    >
      {clientName && <h2 className="mb-6">Choose a document camera to enable for {clientName}</h2>}
      <div className="w-full flex flex-col items-center gap-4">
        <Select
          label="doc-cam-select"
          options={cameras.map(c => ({ name: c.label, value: c.deviceId }))}
          value={selectedCamera?.deviceId}
          onChange={deviceId => {
            const newCamera = cameras.find(c => c.deviceId === deviceId);
            newCamera && setSelectedCamera(newCamera);
          }}
        />
        <div className="w-[400px] aspect-video grid place-items-center">
          {remoteDocumentCamera ? (
            <DailyParticipantMedia video={remoteDocumentCamera.track} isRemote={true} />
          ) : (
            <ClipLoader />
          )}
        </div>
        <Button text="Enable" icon={<IonIcon icon={Icons.camera} />} onClick={activate} />
      </div>
    </Modal>
  );
};

const DocumentCameraSelectModal = observer(_DocumentCameraSelectModal);

const MediaControls = ({
  participant: { participantKey, isRemote, isScreenshare, isDocumentCamera, video, audio, isKicked },
  isPinningDisabled,
}: {
  participant: Participant;
  isPinningDisabled: boolean;
}) => {
  const { meetingStore, participantStore } = useContext(StoreContext);

  const sendAppMessage = useAppMessage();

  const [isSelectingDocumentCamera, setIsSelectingDocumentCamera] = useState(false);

  const isPinned = meetingStore.pinnedParticipantKey === participantKey;
  const isProviderScreenshareMinimized = meetingStore.isProviderScreenshareMinimized;

  const buttonClass = (isSelected = false, isDisabled = false) =>
    `w-6 h-6 rounded-xl text-white text-sm ${
      isSelected ? "bg-red" : isDisabled ? "bg-slate-400" : "bg-slate-600 hover:bg-opacity-80"
    }`;

  const idString = (name: string) => `${name}-media-control-${participantKey}`;

  const showDocCameraButton = isRemote && !isScreenshare && !isDocumentCamera && !!meetingStore.connectedMeetingKey;

  const availableCameras = participantStore.participants[participantKey]?.cameras?.filter(c => !c.isEnabled) || [];
  const hasDocCameras = availableCameras.length > 0;

  const isDocCameraActive = meetingStore.activeDocumentCamera?.participantKey === participantKey;

  const disableDocCamera = async () => {
    await meetingStore.updateDisplayState({ activeDocumentCameraId: null });
    sendAppMessage({ type: DailyMessageType.StopDocumentCamera });
  };

  return (
    <>
      {showDocCameraButton && (
        <IonIconTooltipButton
          uniqueName={idString("doc-camera-window-controls")}
          labelText={
            hasDocCameras
              ? `${isDocCameraActive ? "Disable" : "Enable"} Document Camera`
              : "No Document Cameras Available"
          }
          disabled={!hasDocCameras}
          onClick={() => (isDocCameraActive ? disableDocCamera() : setIsSelectingDocumentCamera(true))}
          className={buttonClass(isDocCameraActive, !hasDocCameras)}
          icon={Icons.camera}
        />
      )}
      {isSelectingDocumentCamera && (
        <DocumentCameraSelectModal
          participantKey={participantKey}
          cameras={availableCameras}
          dismiss={() => setIsSelectingDocumentCamera(false)}
        />
      )}
      {isScreenshare && !isRemote && (
        <IonIconTooltipButton
          uniqueName={idString("screenshare-window-controls")}
          labelText={isProviderScreenshareMinimized ? "Maximize my screenshare view" : "Minimize my screenshare view"}
          onClick={() => {
            meetingStore.toggleProviderScreenshareMinimized();
          }}
          className={buttonClass(isProviderScreenshareMinimized)}
          icon={isProviderScreenshareMinimized ? Icons.expand : Icons.contract}
        />
      )}
      {!isKicked && !isScreenshare && (
        <IonIconTooltipButton
          uniqueName={idString("pin")}
          labelText={isPinningDisabled ? "Pinning disabled" : isPinned ? "Unpin" : "Pin"}
          disabled={isPinningDisabled}
          onClick={() => meetingStore.updateDisplayState({ pinnedParticipantKey: isPinned ? null : participantKey })}
          className={classNames(buttonClass(isPinned, isPinningDisabled))}
          icon={Icons.pin}
        />
      )}
      {video && isRemote && !isKicked && !isScreenshare && (
        <IonIconTooltipButton
          uniqueName={idString("hide")}
          labelText={video.status === "disabled" ? "Show Video" : "Hide Video"}
          onClick={video.toggle}
          propagateClick={false}
          className={buttonClass(video.status === "disabled")}
          icon={video.status === "disabled" ? Icons.videocamOff : Icons.videocam}
          isLoading={video.status === "pending"}
        />
      )}
      {audio && isRemote && !isKicked && !isScreenshare && (
        <IonIconTooltipButton
          uniqueName={idString("mute")}
          labelText={audio.status === "disabled" ? "Unmute" : "Mute"}
          onClick={audio.toggle}
          propagateClick={false}
          className={buttonClass(audio.status === "disabled")}
          icon={audio.status === "disabled" ? Icons.micOff : Icons.mic}
          isLoading={audio.status === "pending"}
        />
      )}
      {isDocumentCamera && (
        <IonIconTooltipButton
          uniqueName={idString("stop-document-cam")}
          labelText="Close Camera"
          onClick={disableDocCamera}
          propagateClick={false}
          className={buttonClass(true)}
          icon={Icons.closeCircleOutline}
        />
      )}
    </>
  );
};

export default observer(MediaControls);
