import { createContext } from "react";
import { pick } from "lodash";
import { ProgressStore } from "@/stores/progress.store";
import { initLogger } from "@/utils/logging.utils";
import { ActivityStore } from "./activity.store";
import { AlertStore } from "./alert.store";
import { ApiStore } from "./api.store";
import { AssessmentStore } from "./assessment.store";
import { ChatStore } from "./chat.store";
import { MeetingParticipantStore } from "./meeting-participant.store";
import { MeetingStore } from "./meeting.store";
import { UserStore } from "./user.store";
import { WhiteboardStore } from "./whiteboard.store";

export const alertStore = new AlertStore();

export const userStore = new UserStore(initLogger("UserStore"));

export const apiStore = new ApiStore(alertStore, userStore, initLogger("API"));
userStore.authApi = apiStore.authApi;

export const meetingStore = new MeetingStore(
  userStore,
  alertStore,
  apiStore.meetingApi,
  apiStore.recordingApi,
  initLogger("MeetingStore"),
);

export const loggerContext = () => ({
  userId: userStore.userId,
  sessionId: userStore.sessionId,
  appThreadId: userStore.appThreadId,
  appointmentId: meetingStore.appointment?.appointmentId,
  connectedMeetingKey: meetingStore.connectedMeetingKey,
  userAuth: userStore.auth,
  students: meetingStore.appointment?.students.map(s => pick(s, "userId", "campusId")),
  userType: userStore.user?.userType,
});
alertStore.logger = initLogger("AlertStore", loggerContext);
userStore.logger.updateContext(loggerContext);
meetingStore.logger.updateContext(loggerContext);

export const chatStore = new ChatStore(userStore, meetingStore, initLogger("ChatStore", loggerContext));

export const whiteboardStore = new WhiteboardStore(
  userStore,
  meetingStore,
  apiStore.whiteboardApi,
  initLogger("WhiteboardStore", loggerContext),
);

export const activityStore = new ActivityStore(
  meetingStore,
  apiStore.meetingApi,
  initLogger("ActivityStore", loggerContext),
);

export const participantStore = new MeetingParticipantStore(
  meetingStore,
  apiStore.meetingApi,
  initLogger("MeetingParticipantStore", loggerContext),
);

export const assessmentStore = new AssessmentStore(activityStore, initLogger("AssessmentStore", loggerContext));

const progressStore = new ProgressStore(
  alertStore,
  meetingStore,
  apiStore.progressApi,
  initLogger("ProgressStore", loggerContext),
);

export const ActivityStoreContext = createContext(activityStore);
export const ApiStoreContext = createContext(apiStore);
export const AlertStoreContext = createContext(alertStore);
export const AssessmentStoreContext = createContext(assessmentStore);
export const UserStoreContext = createContext(userStore);
export const MeetingStoreContext = createContext(meetingStore);
export const ParticipantStoreContext = createContext(participantStore);
export const ChatStoreContext = createContext(chatStore);
export const WhiteboardStoreContext = createContext(whiteboardStore);

export const allStores = {
  activityStore,
  apiStore,
  alertStore,
  assessmentStore,
  userStore,
  meetingStore,
  participantStore,
  chatStore,
  whiteboardStore,
  progressStore,
};
export const StoreContext = createContext(allStores);
